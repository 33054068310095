import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4a1222f0 = () => interopDefault(import('../pages/account-deleted.vue' /* webpackChunkName: "pages/account-deleted" */))
const _2dada626 = () => interopDefault(import('../pages/chats/index.vue' /* webpackChunkName: "pages/chats/index" */))
const _6041cd2e = () => interopDefault(import('../pages/complaints.vue' /* webpackChunkName: "pages/complaints" */))
const _c9cf768a = () => interopDefault(import('../pages/complete-signup.vue' /* webpackChunkName: "pages/complete-signup" */))
const _cce09628 = () => interopDefault(import('../pages/credits/index.vue' /* webpackChunkName: "pages/credits/index" */))
const _86752e32 = () => interopDefault(import('../pages/index-new-design.vue' /* webpackChunkName: "pages/index-new-design" */))
const _5d197964 = () => interopDefault(import('../pages/likes/index.vue' /* webpackChunkName: "pages/likes/index" */))
const _40940390 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _002a8fa2 = () => interopDefault(import('../pages/match-finder.vue' /* webpackChunkName: "pages/match-finder" */))
const _e50b12aa = () => interopDefault(import('../pages/members/index.vue' /* webpackChunkName: "pages/members/index" */))
const _0dbd2537 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _3cb73734 = () => interopDefault(import('../pages/premium.vue' /* webpackChunkName: "pages/premium" */))
const _17a3e655 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _7547b290 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _634ed79d = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _6363b5d9 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _13cbae3f = () => interopDefault(import('../pages/signup-matches.vue' /* webpackChunkName: "pages/signup-matches" */))
const _7abb7726 = () => interopDefault(import('../pages/activate/done.vue' /* webpackChunkName: "pages/activate/done" */))
const _45d52bad = () => interopDefault(import('../pages/activate/error.vue' /* webpackChunkName: "pages/activate/error" */))
const _509ee472 = () => interopDefault(import('../pages/credits/repeat-order.vue' /* webpackChunkName: "pages/credits/repeat-order" */))
const _c363f27e = () => interopDefault(import('../pages/landers/adultonly.vue' /* webpackChunkName: "pages/landers/adultonly" */))
const _df1f8cc4 = () => interopDefault(import('../pages/landers/whoneedsstrings.vue' /* webpackChunkName: "pages/landers/whoneedsstrings" */))
const _11821875 = () => interopDefault(import('../pages/mail-change/do.vue' /* webpackChunkName: "pages/mail-change/do" */))
const _fb0a85da = () => interopDefault(import('../pages/pages/ban.vue' /* webpackChunkName: "pages/pages/ban" */))
const _ccdb24da = () => interopDefault(import('../pages/pages/cookies.vue' /* webpackChunkName: "pages/pages/cookies" */))
const _563df09a = () => interopDefault(import('../pages/pages/faq.vue' /* webpackChunkName: "pages/pages/faq" */))
const _58d72a0c = () => interopDefault(import('../pages/pages/privacy.vue' /* webpackChunkName: "pages/pages/privacy" */))
const _44743673 = () => interopDefault(import('../pages/pages/return-policy.vue' /* webpackChunkName: "pages/pages/return-policy" */))
const _e76b228e = () => interopDefault(import('../pages/pages/service.vue' /* webpackChunkName: "pages/pages/service" */))
const _19e4438b = () => interopDefault(import('../pages/pages/terms.vue' /* webpackChunkName: "pages/pages/terms" */))
const _4833dd60 = () => interopDefault(import('../pages/reset-password/do.vue' /* webpackChunkName: "pages/reset-password/do" */))
const _160e5b21 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _11ba189c = () => interopDefault(import('../pages/landers/alonetonight/_slug.vue' /* webpackChunkName: "pages/landers/alonetonight/_slug" */))
const _0fff2b58 = () => interopDefault(import('../pages/landers/collagelanding/_slug.vue' /* webpackChunkName: "pages/landers/collagelanding/_slug" */))
const _6ab747e8 = () => interopDefault(import('../pages/landers/gallery/_slug.vue' /* webpackChunkName: "pages/landers/gallery/_slug" */))
const _9e414826 = () => interopDefault(import('../pages/landers/iconslanding/_slug.vue' /* webpackChunkName: "pages/landers/iconslanding/_slug" */))
const _26fbcdaa = () => interopDefault(import('../pages/landers/maplanding/_slug.vue' /* webpackChunkName: "pages/landers/maplanding/_slug" */))
const _09e019b7 = () => interopDefault(import('../pages/landers/socialnetwork/_slug.vue' /* webpackChunkName: "pages/landers/socialnetwork/_slug" */))
const _7d4502e0 = () => interopDefault(import('../pages/landers/transitionsvideolanding/_slug.vue' /* webpackChunkName: "pages/landers/transitionsvideolanding/_slug" */))
const _18fa8ade = () => interopDefault(import('../pages/landers/video/_slug.vue' /* webpackChunkName: "pages/landers/video/_slug" */))
const _1c980958 = () => interopDefault(import('../pages/chats/_id/index.vue' /* webpackChunkName: "pages/chats/_id/index" */))
const _42ab31d6 = () => interopDefault(import('../pages/likes/_type.vue' /* webpackChunkName: "pages/likes/_type" */))
const _3f924416 = () => interopDefault(import('../pages/members/_id/index.vue' /* webpackChunkName: "pages/members/_id/index" */))
const _f52350fe = () => interopDefault(import('../pages/transactions/_id.vue' /* webpackChunkName: "pages/transactions/_id" */))
const _62023647 = () => interopDefault(import('../pages/members/_id/chat/index.vue' /* webpackChunkName: "pages/members/_id/chat/index" */))
const _9ed5204a = () => interopDefault(import('../pages/members/_id/landing.vue' /* webpackChunkName: "pages/members/_id/landing" */))
const _6a5ceb84 = () => interopDefault(import('../pages/members/_id/signup.vue' /* webpackChunkName: "pages/members/_id/signup" */))
const _dfe2de3c = () => interopDefault(import('../pages/members/_id/landing2/_slug.vue' /* webpackChunkName: "pages/members/_id/landing2/_slug" */))
const _24f343a3 = () => interopDefault(import('../pages/members/_id/landing3/_slug.vue' /* webpackChunkName: "pages/members/_id/landing3/_slug" */))
const _3253aa33 = () => interopDefault(import('../pages/credits/_id/_paymentmethod.vue' /* webpackChunkName: "pages/credits/_id/_paymentmethod" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-deleted",
    component: _4a1222f0,
    name: "account-deleted"
  }, {
    path: "/chats",
    component: _2dada626,
    name: "chats"
  }, {
    path: "/complaints",
    component: _6041cd2e,
    name: "complaints"
  }, {
    path: "/complete-signup",
    component: _c9cf768a,
    name: "complete-signup"
  }, {
    path: "/credits",
    component: _cce09628,
    name: "credits"
  }, {
    path: "/index-new-design",
    component: _86752e32,
    name: "index-new-design"
  }, {
    path: "/likes",
    component: _5d197964,
    name: "likes"
  }, {
    path: "/login",
    component: _40940390,
    name: "login"
  }, {
    path: "/match-finder",
    component: _002a8fa2,
    name: "match-finder"
  }, {
    path: "/members",
    component: _e50b12aa,
    name: "members"
  }, {
    path: "/notifications",
    component: _0dbd2537,
    name: "notifications"
  }, {
    path: "/premium",
    component: _3cb73734,
    name: "premium"
  }, {
    path: "/pricing",
    component: _17a3e655,
    name: "pricing"
  }, {
    path: "/profile",
    component: _7547b290,
    name: "profile"
  }, {
    path: "/reset-password",
    component: _634ed79d,
    name: "reset-password"
  }, {
    path: "/signup",
    component: _6363b5d9,
    name: "signup"
  }, {
    path: "/signup-matches",
    component: _13cbae3f,
    name: "signup-matches"
  }, {
    path: "/activate/done",
    component: _7abb7726,
    name: "activate-done"
  }, {
    path: "/activate/error",
    component: _45d52bad,
    name: "activate-error"
  }, {
    path: "/credits/repeat-order",
    component: _509ee472,
    name: "credits-repeat-order"
  }, {
    path: "/landers/adultonly",
    component: _c363f27e,
    name: "landers-adultonly"
  }, {
    path: "/landers/whoneedsstrings",
    component: _df1f8cc4,
    name: "landers-whoneedsstrings"
  }, {
    path: "/mail-change/do",
    component: _11821875,
    name: "mail-change-do"
  }, {
    path: "/pages/ban",
    component: _fb0a85da,
    name: "pages-ban"
  }, {
    path: "/pages/cookies",
    component: _ccdb24da,
    name: "pages-cookies"
  }, {
    path: "/pages/faq",
    component: _563df09a,
    name: "pages-faq"
  }, {
    path: "/pages/privacy",
    component: _58d72a0c,
    name: "pages-privacy"
  }, {
    path: "/pages/return-policy",
    component: _44743673,
    name: "pages-return-policy"
  }, {
    path: "/pages/service",
    component: _e76b228e,
    name: "pages-service"
  }, {
    path: "/pages/terms",
    component: _19e4438b,
    name: "pages-terms"
  }, {
    path: "/reset-password/do",
    component: _4833dd60,
    name: "reset-password-do"
  }, {
    path: "/",
    component: _160e5b21,
    name: "index"
  }, {
    path: "/landers/alonetonight/:slug?",
    component: _11ba189c,
    name: "landers-alonetonight-slug"
  }, {
    path: "/landers/collagelanding/:slug?",
    component: _0fff2b58,
    name: "landers-collagelanding-slug"
  }, {
    path: "/landers/gallery/:slug?",
    component: _6ab747e8,
    name: "landers-gallery-slug"
  }, {
    path: "/landers/iconslanding/:slug?",
    component: _9e414826,
    name: "landers-iconslanding-slug"
  }, {
    path: "/landers/maplanding/:slug?",
    component: _26fbcdaa,
    name: "landers-maplanding-slug"
  }, {
    path: "/landers/socialnetwork/:slug?",
    component: _09e019b7,
    name: "landers-socialnetwork-slug"
  }, {
    path: "/landers/transitionsvideolanding/:slug?",
    component: _7d4502e0,
    name: "landers-transitionsvideolanding-slug"
  }, {
    path: "/landers/video/:slug?",
    component: _18fa8ade,
    name: "landers-video-slug"
  }, {
    path: "/chats/:id",
    component: _1c980958,
    name: "chats-id"
  }, {
    path: "/likes/:type",
    component: _42ab31d6,
    name: "likes-type"
  }, {
    path: "/members/:id",
    component: _3f924416,
    name: "members-id"
  }, {
    path: "/transactions/:id?",
    component: _f52350fe,
    name: "transactions-id"
  }, {
    path: "/members/:id/chat",
    component: _62023647,
    name: "members-id-chat"
  }, {
    path: "/members/:id/landing",
    component: _9ed5204a,
    name: "members-id-landing"
  }, {
    path: "/members/:id/signup",
    component: _6a5ceb84,
    name: "members-id-signup"
  }, {
    path: "/members/:id/landing2/:slug?",
    component: _dfe2de3c,
    name: "members-id-landing2-slug"
  }, {
    path: "/members/:id/landing3/:slug?",
    component: _24f343a3,
    name: "members-id-landing3-slug"
  }, {
    path: "/credits/:id/:paymentmethod?",
    component: _3253aa33,
    name: "credits-id-paymentmethod"
  }, {
    path: "/likes/m/:id",
    component: _3f924416,
    name: "likes-m-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
