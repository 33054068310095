export default {
  Back: 'Terug',
  delete: {
    partner_redirect_heading: 'Ergens anders in geintreseerd?',
    partner_redirect_p:
      'We sturen je door naar een partner website... | We sturen je door naar een partner website over {seconds} seconde | We sturen je door naar een partner website over {seconds} seconden',
    partner_redirect_now: 'Nu doorgaan',
    partner_redirect_cancel: 'Op {sitename} blijven',
  },
  icebreaker: {
    heading: 'Stuur {name} een bericht:',
    button: 'Nee, ik wil zelf een bericht sturen',
    reopen: 'Of kies een {link}',
    reopen_link: 'openingszin',
  },
  chatNewMessageTitle:
    '1 NIEUW BERICHT: {name} | {count} NIEUWE BERICHTEN: {name}',
  signup_invite_only: {
    heading: '{sitename} is momenteel invite-only',
    body: 'Zorg dat je een uitnodiging hebt of kom later terug.',
    have_account_q: 'Heb je al een account? {0}',
  },
  'New like!': 'Nieuwe like!',
  likes_search_members_header: 'Laat je interesse zien en Like:',
  likes_search_members_header_first: 'Deel je eerste Like uit aan:',
  chats_search_members_header_first: 'Start je eerste gesprek met:',
  'Use another debit or credit card': 'Gebruik een andere creditcard',
  'Messages - short': 'Chats',
  'Get Premium - short': 'Premium',
  'Search members - short': 'Zoek',
  other_members_near_you: 'Andere interessante leden voor jou',
  email_form_did_you_mean: 'Bedoelde je',
  delete_solutions: {
    header: 'Wacht even, weet je zeker dat je niet iets anders wil doen?',
    change_email: 'Ik heb een nieuw e-mailadres',
    change_email_sub: 'Wijzig je e-mailadres met behoud van je account.',
    change_email_btn: 'E-mailadres wijzigen',
    privacy_mode: 'Extra privacy',
    privacy_mode_sub:
      'Word alleen gezien door mensen die je leuk vind of hebt bericht.',
    privacy_mode_btn: 'Privacy mode inschakakelen',
    delete: 'Ik wil écht mijn account verwijderen.',
    delete_sub:
      'Je hebt nog 14 dagen om je te bedenken via de e-mail die je ontvangt.',
    delete_btn: 'Ga verder',
    tryout_package: 'Blijf lid, speciale aanbieding:',
    tryout_package_sub:
      'Krijg levenslang toegang tot alle premiumfuncties en {credits} credits voor slechts {amount}.',
    tryout_package_btn: 'Ga verder',
    discount: 'Ik wil goedkoper gebruik maken van {sitename}',
    discount_sub: 'Ontvang een levenslang geldige kortingscode',
    discount_btn: 'Bekijk kortingscode',
    discount_header:
      '🎉 Gefeliciteerd, {percentage} korting op al je toekomstige bestellingen',
  },
  delete_reasons: {
    header: 'Waarom wil je {sitename} verlaten?',
    price: '{sitename} is te duur',
    found_someone: 'Ik heb iemand gevonden op {sitename}',
    not_looking: 'Ik ben niet meer op zoek naar contact',
    site_problems: '{sitename} werkt niet goed',
    no_members: 'Er zijn onvoldoende leden bij mij in de buurt',
    time: 'Het kost te veel tijd',
    other: 'Anders namelijk:',
    remarks: 'Opmerkingen',
  },
  email_pref_change: 'Je e-mailadres: {email}',
  INVISIBLE_MODE: {
    heading: 'Onzichtbare modus',
    expl: 'Onzichtbaar zijn is soms spannend. Als je dit aanzet dan verdwijn je uit de zoekresultaten, andere leden kunnen jou niet zien en niet berichten of liken. Als je onzichtbaar bent moet je zelf eerst een bericht of like sturen.',
    warning:
      'Let op: Onzichtbare modus is ingeschakeld maar vergeet niet dat het ook nadelen heeft. Als je onzichtbaar bent moet je zelf eerst een bericht of like sturen. Leden met wie je een gesprek hebt kunnen je wel zien en berichten.',
    checkbox: 'Onzichtbare modus inschakelen',
  },
  notifications_enabled_count: '{enabled} / {total} meldingen ingeschakeld',
  block_user: 'Blokkeer {name}',
  unblock_user: 'Deblokkeer {name}',
  sending_activation_mail: {
    step1: 'Gegevens controleren..',
    step2: 'Account aanmaken..',
    step3: 'Matches zoeken..',
    step4: 'Activatiemail sturen aan: {email}',
  },
  payment_completed_your_chats: 'Ga terug naar je gesprek',
  payment_completed_all_chats: 'Bekijk al je berichten',
  chat_history_limit_reached_warning:
    'Oudere berichten zijn niet meer zichtbaar om jouw privacy te beschermen.',
  order_overview: 'Overzicht',
  Amount: 'Bedrag',
  'Buy now': 'Bestellen',
  'Processing payment': 'Betaling verwerken',
  what_are_chatpals: 'Wat zijn {fictional_plural}?',
  select_package: 'KOOP NU',
  recurring_cc_failed: {
    title: 'Creditcard betaling mislukt',
    content:
      'De betaling kon niet worden afgerond met deze creditcard. Probeer uw gegevens handmatig in te voeren of gebruik een andere betaalmethode.',
    content_paypal:
      'Via PayPal is het ook mogelijk om (zonder account) een betaling te doen met uw Creditcard.',
    cc_btn: 'Probeer opnieuw',
    paypal_btn: 'Betaal met PayPal',
  },
  PAYMENT_COMPLETE_TOAST_TITLE: 'Bestelling voltooid',
  PAYMENT_COMPLETE_TOAST_CONTENT:
    'Bestelling #{payment_id} is voltooid. De credits zijn toegevoegd aan je account.',
  paymentmethods: {
    sct: 'Bankoverschrijving',
    giropay: 'Giropay',
    mastercard: 'Mastercard',
    visa: 'Visa',
    creditcard: 'Creditcard',
    ideal: 'iDEAL',
    bancontact: 'Bancontact',
    applepay: 'Apple Pay',
    paysafecard: 'Paysafe Card',
    maestro: 'Maestro',
    paypal: 'PayPal',
    sofort: 'SOFORT Banking',
    subtext: {
      creditcard: 'Visa / MasterCard / Maestro',
      paypal: 'Ook Creditcard betalingen zonder PayPal account.',
    },
    eps: 'EPS',
  },
  repeatable_orders: {
    heading: 'Herhaal eenvoudig je laatste bestelling',
    package_summary: '{paymentmethod} betaling',
    visit_packages_heading: 'Wijzig betaalmethode of creditbundel',
    visit_packages: 'Bezoek creditpagina',
  },
  matchfinder: {
    found_matches_heading: '{count} matches in jouw regio gevonden!',
    looking_for_matches: 'Zoeken naar matches..',
    heading: 'Wat kies jij?',
    age: {
      age18t35: '18 - 35 jaar',
      age35p: '35+',
    },
    appearance: {
      normal: 'Normaal',
      slut: 'Sexy',
    },
    race: {
      white: 'Blank',
      other: 'Exotisch',
    },
  },
  SYSTEM_CHAT_MESSAGE: 'Systeembericht verstuurd door {sitename}',
  activate_reminder: {
    invalid_email_question: 'Onjuist e-mailadres ingevuld?',
    last_sent_email_expl:
      '{datetime_expl} is er een activatie e-mail verstuurd aan {email}',
  },
  time: {
    at: 'om',
    datetime_at: 'op',
    yesterday_at: 'gisteren om',
  },
  api_completing_signup: 'Aanmelding afronden..',
  api_completing_signup_text: 'Een ogenblik geduld',
  'Click here to open': 'Klik hier direct door naar',
  other_members_header: 'Andere leden op {sitename}',
  'Free membership': 'Gratis lidmaatschap',
  'Premium membership': 'Premium lidmaatschap',
  'Get Premium': 'Premium worden',
  welcome_discount_temporary: 'Tijdelijk {percentage} extra korting',
  memberstatus_free: 'Je bent gratis lid op {sitename}',
  premium_h1: 'Levenslang Premium lid worden',
  DISCOUNTCODE: {
    OPEN_INPUT: 'Kortingscode?',
    label: 'Vul hier je kortingscode in:',
    placeholder: 'Kortingscode',
    button: 'Gebruiken',
    not_found: 'Code niet gevonden',
    already_used: 'Code reeds gebruikt',
    expl: '{code}: {percentage} extra korting.',
    expl_minimum:
      '{code}: {percentage} extra korting bij een minimum orderwaarde van {minimum}.',
    expl_minimum_country:
      '{code}: {percentage} extra korting bij een minimum orderwaarde van {minimum} (In {country}).',
    expl_country: '{code}: {percentage} extra korting (In {country}).',
    clear: 'Kortingscode verwijderen',
  },
  WELCOME_DISCOUNTCODE: {
    expl: 'Welkomstaanbieding: {percentage} korting, gebruik de code: {code}',
    expl_minimum:
      'Welkomstaanbieding: {percentage} korting bij een minimum orderwaarde van {minimum}, gebruik de code: {code}',
    expl_minimum_country:
      'Welkomstaanbieding: {percentage} korting bij een minimum orderwaarde van {minimum} (In {country}), gebruik de code: {code}',
    expl_country:
      'Welkomstaanbieding: {percentage} korting (In {country}), gebruik de code: {code}',
  },
  notifications_disabled: {
    heading: 'E-mail meldingen zijn uitgeschakeld!',
    reason: {
      fbl: 'Je hebt één van onze e-mails als spam gemarkeerd.',
      bounce: 'Je e-mailadres {email} is onjuist.',
      listunsubscribe: 'Je hebt je afgemeld via je e-mailprogramma.',
    },
    solution: {
      fbl: 'Neem contact op met onze {1} voor meer uitleg',
      bounce: 'Pas je {0} aan om weer e-mail meldingen te ontvangen',
      listunsubscribe:
        'Neem contact op met onze {1} om e-mail meldingen weer aan te zetten',
    },
  },
  premium_photo_alert: {
    title: 'Unlock foto',
    content_heading:
      "Wanneer je de eerste keer credits koopt, word je automatisch levenslang premium member en kun je alle foto's van {name} en andere leden zien.",
    content:
      'Premium members krijgen volledig onbeperkt toegang tot alle premium functies, voor altijd, zelfs als je credits op zijn.',
    buy_btn: 'Koop credits en word premium member',
  },
  premium_photolimit_alert: {
    title: 'Foto-limiet bereikt',
    content_heading:
      "Gratis leden mogen maximaal 3 foto's uploaden. Wordt premium member om 25 foto's te mogen uploaden.",
  },
  age_full: '{age} jaar',
  Menu: 'Menu',
  view_profile_dropdown_link: 'Bekijk het profiel van {name}',
  buy_credits_dropdown_link: 'Koop credits',
  'Search Tenor': 'Zoeken in Tenor gifs',
  'Send gift': 'Verstuur cadeautje',
  new_like: 'Nieuw!',
  liked_you_tab:
    'Ontvangen likes | Ontvangen likes ({count} nieuw) | Ontvangen likes ({count} nieuw)',
  liked_by_you_tab: 'Gegeven likes',
  matches_tab: '1 Match! | {count} matches!',
  'Search members': 'Zoek leden',
  'Processing image': 'Verwerken..',
  signup_profile_account_activated: {
    title: 'Account geactiveerd',
    content: 'Je kunt nu gelijk chatten met {name}',
  },
  EMAIL_NOTIFCATIONS: 'E-mail meldingen',
  PUSH_NOTIFCATIONS: 'Push meldingen',
  'Notifications / unsubscribe': 'Meldingen',
  UPDATE_EMAIL_ADDRESS_BTN: 'E-mailadres opslaan',
  PUSH_NOTIFICATIONS_LABEL: 'Wanneer wil je een push notificatie ontvangen?',
  UPDATE_PUSH_PREF_BTN: 'Push voorkeuren opslaan',
  PUSH_PREF_SAVED: 'Push voorkeuren opgeslagen',
  REMOVE_ACCOUNT_LINK_SETTINGS:
    'Account verwijderen of notificatie instellingen wijzigen.',
  'one-time-payment': 'éénmalige betaling',
  'one-time-payment-price': 'éénmalige betaling van {price}',
  'one-time-payment-price-transactioncosts':
    '+ {costs} {paymentmethod} transactiekosten',

  signup_name_equal_to_email:
    'Je profielnaam moet anders zijn dan je e-mailadres',
  signup_name_starts_with_at: 'Je profielnaam mag niet beginnen met een @',
  profile_settings: {
    gender: {
      male: 'Ik ben een man',
      female: 'Ik ben een vrouw',
    },
    sexuality: {
      hetero: 'Ik ben hetero',
      gay: 'Ik ben homo',
      lesbian: 'Ik ben lesbisch',
      bi: 'Ik ben Bi-seksueel',
    },
  },
  ENABLE_PUSH_ALERT: 'Wil je push meldingen voor nieuwe berichten?',
  ENABLE_PUSH_BTN: 'Push melding aanzetten',
  SIGNUP_PROFILE_BTN: 'Update profiel',
  SIGNUP_ABOUTME_BTN: 'Update over mij',
  Optional: 'Optioneel',
  SIGNUP_ADD_PHOTO: 'Klik en kies een foto',
  SIGNUP_CONTINUE_NO_PHOTO: 'Doorgaan zonder foto',
  SIGNUP_CONTINUE_NO_LOCATION: 'Doorgaan zonder woonplaats',
  SIGNUP_CONTINUE_NO_PERSONAL_INFO: 'Doorgaan zonder persoonlijke gegevens',
  SIGNUP_CONTINUE_NO_ABOUT_ME: 'Doorgaan zonder profieltekst',
  LANDER1: {
    message_placeholder: 'Typ je gratis bericht aan {name}...',
    send_msg_btn: 'Stuur {name} een gratis bericht',
    AGE_DESCR: 'Leeftijd {age}',
  },
  CREATE_ACCOUNT: {
    SIGNUP_PUSH_NO: 'Doorgaan zonder push melding',
    signup_push_btn: 'Meldingen aanzetten',
    terms_text:
      'Door een account te creëren ga je akkoord met de {terms} en het {privacy}.',
    terms: 'voorwaarden',
    privacy: 'privacybeleid',
    existing_account_active:
      'Er bestaat al een account met dit e-mailadres, {0}.',
    existing_account_active_CTA: ' klik hier om in te loggen',
    deleted_account_exists:
      'Je account is nog niet definitief verwijderd. Je kunt je account nog heractiveren door in de e-mail te klikken die we je hebben gestuurd tijdens het verwijderen van je account. Voor hulp; neem contact op met de {0}',
    deleted_account_service_CTA: 'Klantenservice',
  },
  important: 'Belangrijk',
  CREATE_ACCOUNT_PROFILE: {
    signup_push_header:
      'Wil je een melding ontvangen als {name} je een bericht stuurt?',
    step0_header: 'Nog 1 stap om {name} te berichten:',
    step1_header:
      'Verhoog je kansen met {name} door een foto aan je profiel toe te voegen:',
    step2_header: 'Laat {name} meer over jezelf weten:',
    step3_header: 'Laat {name} meer over jezelf weten:',
    step4_header: 'Laat {name} meer over jezelf weten:',
    complete_header: 'Nog één laatste stap!',
    complete_text:
      'Bevestig je e-mailadres om je bericht aan {name} te versturen!',
    complete_btn: 'Bezoek {sitename}',
    check_email: 'Check direct je inbox: {email}',
    check_spambox:
      '{important}: Kijk in je spam map als je de activatiemail niet binnen 1 minuut hebt ontvangen.',
    change_email: 'E-mailadres wijzigen',
    step0_btn: 'Verstuur bericht',
  },
  CREATE_ACCOUNT_NO_PROFILE: {
    signup_push_header:
      'Wil je meldingen ontvangen als één van onze leden je een bericht stuurt?',
    step0_header: 'Maak nu je gratis account aan:',
    step1_header:
      'Verhoog je kansen door een foto aan je profiel toe te voegen:',
    step2_header: 'Laat meer over jezelf weten:',
    step3_header: 'Laat meer over jezelf weten:',
    step4_header: 'Laat meer over jezelf weten:',
    check_email: 'Check direct je inbox: {email}',
    complete_header: 'Nog één laatste stap!',
    complete_text_important: 'BELANGRIJK: Bevestig je e-mailadres!',
    complete_text:
      'Leden ontvangen jouw berichten niet totdat je e-mailadres bevestigd is.',
    step0_btn: 'Aanmelden',
  },
  CLICK_ACTIVATE_MAIL: 'Klik op de link in de e-mail die je hebt ontvangen',
  MAIL_SENT: 'Mail verzonden',
  'Send new confirmation e-mail': 'Stuur nieuwe activatie e-mail',
  PROFILE_PHOTO_ALERT: 'Meer contact? {0} en vergroot je kansen!',
  PROFILE_PHOTO_ALERT_ACTION: 'Voeg een profielfoto en beschrijving toe',
  CONFIRM_EMAIL:
    'Bevestig je e-mailadres. Leden ontvangen jouw berichten niet totdat je e-mailadres bevestigd is.',
  Year: 'Jaar',
  Month: 'Maand',
  Day: 'Dag',
  Login: 'Inloggen',
  CHAT_CREDITS_REMAINING: 'Saldo: {count} credit | Saldo: {count} credits',
  today: 'Vandaag',
  about_you_general: 'Over mij',
  living_area: 'Woonomgeving',
  pictures: "Mijn foto's",
  country_label: 'Land:',
  region_label: 'Provincie:',
  region: 'Provincie',
  living_label: 'Woonplaats:',
  living_input: 'Woonplaats',
  save_living_area: 'Woonomgeving opslaan',
  popup_saved: 'Opgeslagen',
  popup_profile: 'Je profiel is gewijzigd.',
  popup_birth: 'Ongeldige geboortedatum.',
  popup_error: 'Er is een fout opgetreden.',
  popup_location: 'Je woonomgeving is gewijzigd.',
  popup_picgone: 'Foto verwijderd.',
  popup_picgonetitle: 'Je foto is verwijderd.',
  choose_region: 'Kies je provincie',
  choose_country: 'Kies je land',
  choose: 'Maak een keuze',
  inspection_picture: 'Foto wordt gekeurd',
  PROFILEIMAGE: 'Profielfoto',
  CONFIGURE_PROFILEIMAGE: 'Instellen als profielfoto',
  DELETE_PHOTO: 'Foto verwijderen',
  ADD_PHOTO: 'Nieuwe foto toevoegen',
  information: 'Persoonlijke informatie',
  LOGIN_REQUIRED: {
    chat: 'Je moet ingelogd zijn om te chatten',
    photo: "Om meer foto's van {name} te zien moet je ingelogd zijn",
    gift: 'Om een cadeautje te sturen moet je ingelogd zijn',
    like: 'Je moet ingelogd zijn om een like te geven',
  },
  ERROR_TRY_AGAIN_LATER:
    'Er is een fout opgetreden, probeer het later opnieuw.',
  DELETE_ACCOUNT: 'Account verwijderen',
  EMAIL_NOTIFICATIONS_LABEL: 'Wanneer wil je een e-mail melding ontvangen?',
  EMAIL_INVISIBLE_OTHERS: 'Je e-mailadres is niet zichtbaar voor andere leden.',
  EMAILADDRESS: 'E-mailadres',
  EMAILADDRESS_label: 'E-mailadres:',
  EMAIL_PREF: 'E-mailadres wijzigen',
  UPDATE_EMAIL_PREF_BTN: 'E-mailvoorkeuren opslaan',
  notifications: {
    new_conversations: 'Nieuw gesprek',
    new_messages: 'Nieuw bericht',
    like: 'Like ontvangen',
    unread_message_reminders: 'Ongelezen bericht herinnering',
    promotions: 'Kortingsacties',
    weekly_updates: 'Nieuwe leden',
    news: 'Site nieuws',
    reminders: 'Herinnering voor ongelezen notificaties',
  },
  EMAIL_PREF_SAVED: 'Je e-mailvoorkeuren zijn opgeslagen',
  SAVED: 'Opgeslagen!',
  ERROR: 'Fout',
  fake_profile_warning: '{fictional_singular}: Fysiek contact niet mogelijk',
  like_icon_title: 'Je vindt {name} leuk',
  like_match_icon_title: 'Match! Jullie vinden elkaar leuk',
  like_received_icon_title: '{name} vindt jou leuk!',
  index_important_modal: 'Belangrijke informatie',
  likes: 'likes',
  Likes: 'Likes',
  Members: 'Leden',
  Messages: 'Berichten',
  Credits: 'Credits',
  'My account': 'Mijn account',
  'My profile': 'Mijn profiel',
  Settings: 'Instellingen',
  Logout: 'Uitloggen',
  personal: 'Over mij',
  reason: 'Reden van aanmelding',
  sexuality: 'Seksualiteit',
  name: 'Naam',
  private_photos_header: "Privé foto van {name} | Privé foto's van {name}",
  age: 'Leeftijd',
  tattoos: 'Tatoeages',
  gender: 'Geslacht',
  male: 'Man',
  female: 'Vrouw',
  man: 'Man',
  woman: 'Vrouw',
  hetero: 'Hetero',
  gay: 'Gay',
  lesbian: 'Lesbisch',
  bi: 'Bi-seksueel',
  yes: 'Ja',
  no: 'Nee',
  city: 'Woonplaats',
  yesterday: 'Gisteren',
  members: 'leden',
  credit_count: '1 credit | {count} credits',
  loading_payment_options: 'Bezig met ophalen betaalmogelijkheden.',
  select_payment_method: 'Betaling',
  select_bank: 'Kies je bank',
  pay_with: 'Betaal éénmalig {amount} met {method_name}',
  payment_completed_header: 'Jouw bestelling #{payment_id} is afgerond',
  payment_completed_text: '{credits} credits zijn bijgeschreven in je account.',
  paymentstatus_loading: 'Bezig met ophalen van jouw bestelling',
  payment_pending_header: 'Jouw bestelling wordt verwerkt.',
  payment_pending_text: 'Een ogenblik geduld...',
  payment_help_text:
    'Wij bieden geen abonnementen aan, je betaalt eenvoudig wanneer je credits wil en zit verder nergens aan vast. Vragen over je betaling of hulp nodig? Neem contact op via {0}. Ongebruikte credits kun je {1}',
  payment_terms: {
    text: 'Door een bestelling te plaatsen ga je akkoord met het {1} en {2} van {0}.',
    return_policy: 'retourbeleid',
    terms: 'algemene voorwaarden',
  },

  payment_refunds_link: '{refund_days} dagen gratis annuleren.',
  payment_failed_header: 'Betaling mislukt',
  payment_failed_text: 'De betaling is mislukt of geannuleeerd.',
  payment_failed_try_again_text:
    'Opnieuw proberen met een andere betaalmethode.',
  premium_member: 'Premium member',
  premium_member_expl:
    'Bij aanschaf van je eerste Premium credit bundel word je direct {0} en krijgt je account extra functionaliteiten zoals toegang tot alle member foto’s!',
  credits_current: 'Je hebt momenteel: {0}',
  'Popular!': 'Meest gekozen',
  buy_credits_now: 'Koop nu credits:',
  profile_name_label: 'Profielnaam:',
  profile_name_placeholder: 'Profielnaam',
  profile_name_warning: 'Deze naam zien andere leden in je profiel',
  gender_label: 'Ik ben een',
  save_profile_btn: 'Profiel opslaan',
  date_of_birth_error: 'Vul uw geboortedatum in ({min_age}+ jaar)',
  date_of_birth_label: 'Geboortedatum:',
  date_of_birth_only_age: 'Andere leden zien alleen je leeftijd in je profiel.',
  chatten: 'Start met chatten',
  inloggen: 'Inloggen',
  aanmelden: 'Gratis aanmelden',
  my_profile_intro:
    'Vertel de andere members wat meer over jezelf. Wie je bent, waar je vandaan komt en hoe je uitziet.',
  my_profile_view_link: 'Bekijk hoe andere members jouw profiel zien.',
  start_conversation: 'Stuur {name} een bericht',
  like_btn: 'Like {name}',
  remove_like_btn: 'Verwijder like',
  send_gift: 'Cadeau',
  member_page_photo_count_btn: "1 foto online | {count} foto's online",
  no_photo_access:
    "{name} heeft {photocount} foto's online geplaatst. Als {link} ontvang je toegang tot alle privé-foto's van {name} en andere members.",
  profile_page_contact_h1: 'Contact opnemen met {name}?',
  no_photo_access_link: 'Premium member',
  member_search: {
    looking_for_gender: 'Ik zoek een:',
    looking_for_country: 'Land:',
    looking_for_region: 'Provincie:',
    looking_for_age: 'Leeftijd:',
    all_ages: 'Alle leeftijden',
    age_range: '{0} - {1} jaar',
    over_age: '{age}+',
    select_country_first: 'Kies eerst je land',
    all_regions: 'Alle provincies',
    none_found: 'Er zijn geen leden gevonden die aan je selectie voldoen.',
  },
  complaints: {
    title: 'Dien een klacht in',
  },
  likes_page: {
    intro:
      'Bekijk hieronder de door jou gegeven Likes {0} en van wie je een Like {1} hebt ontvangen. Geven jullie elkaar een Like dan heb je een Match {2} en kun je een geweldige chat verwachten!',
    you_have_liked: 'Je hebt {0} geliket en {1} jouw profiel geliket.',
    number_of_likes: '1 lid | {count} leden',
    number_of_likes_received: '1 lid heeft | {count} leden hebben',
    no_likes_title: 'Je hebt nog geen likes gegeven.',
    no_likes_received_title: 'Je hebt nog geen likes ontvangen.',
    no_likes_content: 'Zoek direct naar leuke {0} en geeft ze een like!',
    no_likes_received_content: "Voeg wat leuke foto's toe en wie weet!",
  },
  footer_menu: {
    return_policy: 'Retourbeleid',
    about_us: 'Over ons',
    terms: 'Voorwaarden',
    privacy: 'Privacybeleid',
    cookies: 'Cookies',
    ban: 'Toegangsverbod',
    pricing: 'Tarieven',
    complaints: 'Klachten',
    faq: 'Veelgestelde vragen',
    support: 'Klantenservice',
  },
  chat: {
    is_typing: '{name} is aan het typen..',
    last_message_photo: 'Er is een foto verstuurd',
    last_message_gift: 'Er is een cadeautje verstuurd',
    not_confirmed:
      'Je e-mailadres is nog niet bevestigd. {name} kan pas weer reageren zodra jouw e-mailadres bevestigd is. Klik op de link in de e-mail die je hebt ontvangen.',
    no_messages: 'Nog geen berichten..',
    loading_messages: 'Berichten worden geladen..',
    char_count: '1 teken | {count} tekens',
    send: 'Verstuur',
    send_on_enter: 'Verstuur op enter',
    first_message: 'Stuur snel je eerste bericht naar {name}',
    load_older_messages: 'Laad oudere berichten',
    input_placeholder: 'Typ je bericht..',
    credit_modal: {
      title: 'Je credits',
    },
    credit_alert: {
      title: 'Je hebt onvoldoende credits',
      content:
        'Je hebt onvoldoende credits om dit bericht te versturen aan {name}.',
      buy_btn: 'Koop credits',
    },
    premium_alert: {
      title: 'Deze functie is alleen beschikbaar voor premium leden.',
      content: 'Koop een credit bundel om je account te upgraden naar premium.',
      buy_btn: 'Koop credit bundel',
    },
    no_chats_title: 'Je hebt nog geen berichten',
    no_chats_content: 'Zoek direct naar leuke {0} en begin met chatten!',
    your_turn: 'Jouw beurt',
  },
  settings: {
    change_password: 'Wachtwoord wijzigen',
    confirm_new_email:
      'Klik op de e-mail die we zojuist naar je nieuwe e-mailadres hebben gestuurd.',
    confirm_new_email_title: 'Bevestig je nieuwe e-mailadres',
    email_changed: 'Je e-mailadres is gewijzigd.',
    email_duplicate: 'Dit e-mailadres heeft al een account op deze site.',
    email_invalid: 'Nieuw e-mailadres invalide.',
    email_changing: 'Je e-maildres wordt nu gewijzigd',
    account_deleted: 'Account verwijderd',
    reactivate_by_email:
      'Je kunt je account heractiveren in de e-mail die we hebben gestuurd.',
    confirm_account_delete: 'Weet je zeker dat je je account wil verwijderen?',
    check_old_password: 'Controleer je oude wachtwoord.',
    password_changed: 'Wachtwoord gewijzigd',
  },
  forms: {
    email_label: 'E-mail:',
    email_placeholder: 'E-mailadres',
    password_label: 'Wachtwoord:',
    password_placeholder: 'Wachtwoord',
    create_password1_label: 'Wachtwoord:',
    create_password1_placeholder: 'Kies een wachtwoord',
    new_password1_label: 'Nieuw wachtwoord:',
    new_password1_placeholder: 'Nieuw wachtwoord',
    new_password2_label: 'Nieuw wachtwoord herhalen:',
    new_password2_placeholder: 'Nieuw wachtwoord herhalen',
    old_password_label: 'Oud wachtwoord:',
    old_password_placeholder: 'Oud wachtwoord',
  },
  reset_password: {
    title: 'Wachtwoord vergeten',
    content: 'Vul het e-mailadres in waarmee je je hebt aangemeld.',
    btn: 'Stuur nieuw wachtwoord',
    not_found: 'E-mailadres niet gevonden',
    done: 'Er is een e-mail verstuurd om je wachtwoord opnieuw in te stellen.',
    not_equal: 'De opgegeven wachtwoorden zijn niet gelijk.',
    configured: 'Je nieuwe wachtwoord is ingesteld, je kunt nu inloggen.',
    configured_title: 'Wachtwoord ingesteld.',
    link_expired: 'Link verlopen of invalide, vraag een nieuwe e-mail aan',
    reset_btn: 'Wachtwoord instellen',
    change_btn: 'Wachtwoord instellen',
  },
  login: {
    create_new_account: 'Account aanmaken',
    header: 'Login op {name}',
    content: 'Om in te loggen vul je e-mailadres en wachtwoord in.',
    btn: 'Inloggen',
    reset_password: 'Wachtwoord vergeten',
    failed: 'Login mislukt, probeer opnieuw',
    too_many_attempts_blocked:
      'Te veel foute pogingen, account voor {minutes} min geblokkeerd.',
  },
  activate: {
    failed: 'Activeren mislukt',
    login_now: 'Je kunt nu inloggen',
    activate_done: 'Je account is geactiveerd',
    failed_help: 'Er is iets mis gegaan, neem contact op met {0} voor hulp',
    get_premium: 'Premium worden',
    get_premium_btn: 'Premium worden',
    get_premium_subtext:
      'Premium members hebben 46% meer kans om de perfecte match te vinden!',
    view_all_members: 'Bekijk alle leden op {sitename}',
    search_members_btn: 'Zoek leden',
    search_members_subtext:
      'Je bent Free Member en kunt maximaal 2 gesprekken starten',
    signup_profile: 'Ga verder met {name}',
    signup_profile_btn: 'Chat met {name}',
    signup_profile_subtext:
      'Je bent Free Member en kunt {numMessages} gratis berichten ({numCredits} credits) versturen',
  },
  discount: 'korting',
  'Delete message': 'Bericht verwijderen',
  'Delete chat': 'Gesprek verwijderen',
  chat_delete_confirmation: {
    title: 'Weet je zeker dat je je chat met {profile_name} wil verwijderen?',
    body: 'Alle berichten gaan verloren, zowel jij als {profile_name} kunnen dit niet herstellen!',
  },
  user_block_confirm: {
    body: 'Weet je zeker dat je {profile_name} wil blokkeren? Eventuele chats en likes worden verwijderd, {profile_name} kan je profiel niet meer bekijken of contact met je opnemen.',
  },
  blocked_by_text:
    '{name} heeft je geblokkeerd. Je kunt het profiel van {name} niet meer bekijken.',
  payments: {
    add_new_card: 'Nieuwe kaart toevoegen',
    delete_card: 'Kaart verwijderen',
  },
  interests: {
    title: 'Interesses',
    relationship: 'Relatie',
    onenightstand: 'One night stand',
    kissing: 'Zoenen',
    hugging: 'Knuffelen',
    massages: 'Massages',
    lingerie: 'Lingerie',
    sex: 'Seks',
    safesex: 'Veilige seks',
    publicsex: 'Openbare seks',
    oralsex: 'Orale seks',
    analsex: 'Anale seks',
    sextoys: 'Seks Speeltjes',
    masturbating: 'Masturberen',
    exchangingpictures: 'Fotos uitwisselen',
    threesome: 'Trio',
    gangbang: 'Gangbang',
    bondage: 'Bondage',
    sadomasochism: 'SM',
    gym: 'Sportschool',
    sports: 'Sporten',
    dancing: 'Dansen',
    travelling: 'Reizen',
    adventure: 'Avontuur',
    party: 'Feesten',
    tattoos: 'Tatoeages',
    music: 'Muziek',
    movies: 'Films',
    videogames: 'Videospellen',
    politics: 'Politiek',
    outdoors: 'Buiten zijn',
    gardening: 'Tuinieren',
    hiking: 'Wandelen',
    biking: 'Fietsen',
    reading: 'Lezen',
    animals: 'Dieren',
    beach: 'Strand',
    art: 'Kunst',
    photography: 'Fotografie',
    theater: 'Theater',
    shopping: 'Winkelen',
    fashion: 'Mode',
    astrology: 'Astrologie',
    cooking: 'Koken',
    foodie: 'Foodie',
    bbq: 'BBQ',
    sushi: 'Sushi',
    vegetarian: 'Vegetarisch',
    wine: 'Wijn',
    beer: 'Bier',
    tea: 'Thee',
    coffee: 'Koffie',
  },
  hair_color: 'Haarkleur',
  brunette: 'Brunette',
  blond: 'Blond',
  brown: 'Bruin',
  white: 'Wit',
  bald: 'Kaal',
  relationship_status: 'Relatiestatus',
  divorced: 'Gescheiden',
  'living together': 'Samenlevend',
  married: 'Getrouwd',
  unmarried: 'Ongetrouwd',
  single: 'Vrijgezel',
  widowed: 'Weduwe',

  black: 'Zwart',
  'dark blond': 'Donker blond',
  'dark brown': 'Donker bruin',
  gray: 'Grijs',
  red: 'Rood',
  other: 'Anders',
  slim: 'Slank',
  normal: 'Gemiddeld',
  fat: 'Overgewicht',
  body_type: 'Lichaamsbouw',
  blue: 'Blauw',
  green: 'Groen',
  hazel: 'Hazel',
  eye_color: 'Kleur ogen',
  xcams: {
    exclusive: 'UITGELICHT',
    live: 'NU LIVE',
  },
};
